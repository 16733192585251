<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<!-- START HERE -->
							<article class="article-body" id="article-body" rel="image-enlarge">
								<p dir="ltr">To install the correct version of {{ $store.state.brandInfo.displayName }} on Android box follow these steps.</p>
								<p><br /></p>
								<p>1. Open your browser and enter the the URL:</p>
								<p><a href="https://apg.ooo/sstv.apk"></a><a href="http://apg.ooo/aptv.apk">http://apg.ooo/aptv.apk</a></p>
								<p>
									<img
										src="../../../assets/images/rMKtt6iMjrO0OtVWnovaBV92z7fTbp1WcQ.png?1591302812"
										style="width: 608px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48043035441"
									/>
								</p>
								<p><br /></p>
								<p>2. Click Enter, and the file will start to download, when you downloading it, you will see at the top of your screen an Arrow.</p>
								<p>
									<img
										src="../../../assets/images/ZgpnRzZKBE_tQRQfayGwe-n33LVkulLFsg.png?1582730878"
										style="width: 553px"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48029166725"
									/>
								</p>
								<p>3. Once download is complete you can scroll down the menu from the top using the mouse, and click on the APTV.APK</p>
								<p>Once you done that, the installation should run, if you see the installation wizard, please jump to step 6.</p>
								<p>
									<img
										src="../../../assets/images/ya8IVJ-V0JmUJmJw5qtqnYxQlESeKddPmA.png?1591303142"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48043036104"
									/>
								</p>
								<p><br /></p>
								<p>4. Open Google Play and install Xplore</p>
								<p>
									<img
										src="../../../assets/images/aTU2Ht2Aw7LFnIJ5UYKspM5XzRgBKkaWbg.png?1582731301"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48029168507"
									/>
								</p>
								<p><br /></p>
								<p>5. Open the app, and search for the downloaded <strong>aptv.apk</strong> file.</p>
								<p>It should be under Download Folder (Please notice in picture its sstv.apk, and not aptv.apk, we updated link).</p>
								<p>
									<img :src="getPic('M3u.png')" style="width: 426px" class="fr-fic fr-fil fr-dib" data-attachment="[object Object]" data-id="48029168986" />
								</p>
								<p><br /></p>
								<p>6. Once you clicked it, it should open the Install Wizard, Click on Install.</p>
								<p>
									<img
										src="../../../assets/images/SDRJuGahZGEdbAgv902DrEBvaTJY6CCJ9g.png?1591302876"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48043035557"
									/>
								</p>
								<p><br /></p>
								<p>7. Once it finish Installing, click on Open</p>
								<p>
									<img
										src="../../../assets/images/tqjbnJ9pBy_KivaTdrZ6QuqCiaPXHXzPkA.png?1591302945"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48043035752"
									/>
								</p>
								<p><br /></p>
								<p>8. Use your remote to enter your Email/Username in the [Email or Username] field and then press NEXT</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br style="box-sizing: border-box" />
								</p>
								<p>9. After entering your Email/Username click the NEXT Button and enter your password.</p>
								<p>And enter your password in the [Password] field (second line)</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<img
										src="../../../assets/images/-f-h6Fk_E9k2172fDC6Pq9SLJMYk1VhDew.png?1591303015"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48043035855"
									/>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									10. After entering your Password click the LOGIN Button.
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<br />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin-bottom: 0px;
										margin-left: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
									"
								>
									<img
										src="../../../assets/images/b3FntrilxRTqT7zQXgjTz0QK8HkxqM15dA.png?1591303080"
										style="width: auto"
										class="fr-fic fr-fil fr-dib"
										data-attachment="[object Object]"
										data-id="48043035959"
									/>
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									<br />
								</p>
								<p
									style="
										box-sizing: border-box;
										margin: 0px;
										font-size: 13px;
										line-height: 18px;
										word-break: normal;
										overflow-wrap: break-word;
										color: rgb(24, 50, 71);
										font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
										font-style: normal;
										font-variant-ligatures: normal;
										font-variant-caps: normal;
										font-weight: 400;
										letter-spacing: normal;
										orphans: 2;
										text-align: start;
										text-indent: 0px;
										text-transform: none;
										white-space: normal;
										widows: 2;
										word-spacing: 0px;
										-webkit-text-stroke-width: 0px;
										text-decoration-style: initial;
										text-decoration-color: initial;
									"
								>
									Thats all, Enjoy!
								</p>
							</article>
							<!-- FINISH HERE -->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import _ from 'lodash';

export default {
	name: 'manuallyInstallOnAndroidBox',
	data() {
		return {
			title: this.$route.meta.title
		};
	},
	methods: {
		getPic(picSuffix) {
			const appName = _.lowerCase(this.$store.state.brandInfo.appName).replace(/ /g, '');
			if (!appName) {
				return;
			}

			return require(`@/assets/images/with-logo/${appName}${picSuffix}`);
		}
	}
};
</script>

<style scoped></style>
